import React from 'react';

const PauseIcon = () => {
  return (
    <svg
      height="30"
      width="30"
      viewBox="0 0 271.953 271.953"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Pause video</title>
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {' '}
        <g>
          {' '}
          <g>
            {' '}
            <path
              style={{ fill: '#ffff' }}
              d="M135.977,271.953c75.097,0,135.977-60.879,135.977-135.977S211.074,0,135.977,0S0,60.879,0,135.977 S60.879,271.953,135.977,271.953z M135.977,21.756c62.979,0,114.22,51.241,114.22,114.22s-51.241,114.22-114.22,114.22 s-114.22-51.241-114.22-114.22S72.992,21.756,135.977,21.756z"
            />{' '}
            <path
              style={{ fill: '#ffff' }}
              d="M110.707,200.114c7.511,0,13.598-6.086,13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598 c-7.511,0-13.598,6.086-13.598,13.598v103.342C97.109,194.028,103.195,200.114,110.707,200.114z"
            />{' '}
            <path
              style={{ fill: '#ffff' }}
              d="M165.097,200.114c7.511,0,13.598-6.086,13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598 S151.5,75.663,151.5,83.174v103.342C151.5,194.028,157.586,200.114,165.097,200.114z"
            />{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

export default PauseIcon;
